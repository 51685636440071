import { Component, Input } from '@angular/core';
import { ILogLine } from '../../../model/log.model';

@Component({
  selector: '[app-log-line]',
  templateUrl: './log-line.component.html',
  styleUrls: ['./log-line.component.scss']
})
export class LogLineComponent {

  @Input()
  public logLine!: ILogLine;

  public showInfo = false;

  public toggleShowInfo(): void {
    this.showInfo = !this.showInfo;
  }

}
