import { NgModule } from '@angular/core';
import { ListPage } from './pages/list/list.page';
import { CompanyRoutingModule } from './company-routing.module';
import { SharedModule } from '../../shared/shared.module';
import { CreatePage } from './pages/create/create.page';
import { DetailsPage } from './pages/details/details.page';
import { EditPage } from './pages/edit/edit.page';
import { FormComponent } from './components/form/form.component';



@NgModule({
  declarations: [
    ListPage,
    CreatePage,
    DetailsPage,
    EditPage,
    FormComponent,
  ],
  imports: [
    CompanyRoutingModule,
    SharedModule,
  ]
})
export class CompanyModule { }
