<app-container>
  <app-content-block>
    <h1>Companies</h1>
    <div *ngIf="companies$ | async as companies">
      <table class="list dark">
        <tr>
          <th>Company name</th>
          <th>Bank accounts</th>
        </tr>
        <tr
          *ngFor="let company of companies"
          [routerLink]="['/companies', company.id]"
          class="clickable"
        >
          <td>{{ company.name }}</td>
          <td>{{ company.numberOfBankAccounts }}</td>
        </tr>
      </table>

      <div class="table-bottom-options" *ngIf="isAdmin()">
        <button class="create" [routerLink]="['/companies', 'create']">Create a new company</button>
      </div>
    </div>
  </app-content-block>
</app-container>
