<app-container>
  <app-content-block>
    <ng-container *ngIf="company$ | async as company">
      <div class="content-block">

        <button
          mat-icon-button
          color="basic"
          class="settings"
          [matMenuTriggerFor]="menu"
        >
          <mat-icon svgIcon="settings" class="settings"></mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <button mat-menu-item [routerLink]="['/companies', company.id, 'edit']">
            <mat-icon svgIcon="edit"></mat-icon>
            Edit company
          </button>
          <button mat-menu-item class="delete" (click)="showDeleteDialog(company)">
            <mat-icon svgIcon="delete"></mat-icon>
            Delete company
          </button>
        </mat-menu>
        
        <h1>{{ company.name }}</h1>

      </div>
      <ng-container *ngIf="bankAccounts$ | async as bankAccounts">

        <app-bank-accounts [bankAccounts]="bankAccounts"></app-bank-accounts>

        <div class="table-bottom-options">
          <button class="create" [routerLink]="['/bank-accounts', 'create', company.id]">Add a new bank account</button>
        </div>

      </ng-container>
    </ng-container>
  </app-content-block>
</app-container>
