import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { FirestoreService } from '../../modules/firebase/services/firestore.service';
import { IBankAccount } from '../model/bank-account.model';
import { IStatement } from '../model/statement.model';

@Injectable({
  providedIn: 'root'
})
export class StatementService extends FirestoreService<IStatement> {

  private collection = '';

  public getCollection(): string {
    return this.collection;
  }

  private setBankAccountId(bankAccountId: string): void {
    this.collection = `bank-accounts/${bankAccountId}/statements`;
  }

  public getByBankAccountId(bankAccountId: string, limit = 100): Observable<IStatement[]> {
    this.setBankAccountId(bankAccountId);
    return this.firestore.collection<IStatement>(
      this.collection,
      ref => ref.orderBy('date', 'desc').limit(limit),
    ).valueChanges({ idField: 'id' });
  }

}
