import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { IImportLog } from '../../../../shared/model/log.model';
import { ImportLogService } from '../../../../shared/services/import-log.service';

@Component({
  selector: 'app-import-logs',
  templateUrl: './import-logs.component.html',
  styleUrls: ['./import-logs.component.scss']
})
export class ImportLogsComponent implements OnInit {

  @Input()
  public bankAccountId!: string;

  public importLogs$!: Observable<IImportLog[]>;

  constructor(
    private importLogService: ImportLogService,
  ) { }

  public ngOnInit(): void {
    this.importLogs$ = this.importLogService.getAll(this.bankAccountId);
  }

}
