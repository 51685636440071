import { Component, Input } from '@angular/core';
import { ILogLine } from '../../../model/log.model';

@Component({
  selector: 'app-log-line-info',
  templateUrl: './log-line-info.component.html',
  styleUrls: ['./log-line-info.component.scss']
})
export class LogLineInfoComponent {

  @Input()
  public logLine!: ILogLine;

}
