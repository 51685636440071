import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { EndUserLicenseAgreementPage } from './pages/end-user-license-agreement/end-user-license-agreement.page';
import { HomePage } from './pages/home/home.page';
import { PrivacyPolicyPage } from './pages/privacy-policy/privacy-policy.page';

const routes: Routes = [
  {
    path: '',
    component: HomePage,
  },
  {
    path: 'privacy-policy',
    component: PrivacyPolicyPage,
  },
  {
    path: 'end-user-license-agreement',
    component: EndUserLicenseAgreementPage,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class PagesRoutingModule { }
