import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CreatePage } from './pages/create/create.page';
import { DetailsPage } from './pages/details/details.page';
import { EditPage } from './pages/edit/edit.page';
import { ExportLogPage } from './pages/export-log/export-log.page';
import { ExportSettingsPage } from './pages/export-settings/export-settings.page';
import { ImportLogPage } from './pages/import-log/import-log.page';
import { ImportSettingsPage } from './pages/import-settings/import-settings.page';
import { ListPage } from './pages/list/list.page';
import { StatementsPage } from './pages/statements/statements.page';

const routes: Routes = [
  {
    path: 'bank-accounts',
    component: ListPage,
  },
  {
    path: 'bank-accounts/create/:companyId',
    component: CreatePage,
  },
  {
    path: 'bank-accounts/:bankAccountId',
    component: DetailsPage,
  },
  {
    path: 'bank-accounts/:bankAccountId/edit',
    component: EditPage,
  },
  {
    path: 'bank-accounts/:bankAccountId/import-logs/:importLogId',
    component: ImportLogPage,
  },
  {
    path: 'bank-accounts/:bankAccountId/export-logs/:exportLogId',
    component: ExportLogPage,
  },
  {
    path: 'bank-accounts/:bankAccountId/statements',
    component: StatementsPage,
  },
  {
    path: 'bank-accounts/:bankAccountId/import-settings',
    component: ImportSettingsPage,
  },
  {
    path: 'bank-accounts/:bankAccountId/export-settings',
    component: ExportSettingsPage,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class BankAccountRoutingModule { }
