import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { FirestoreService } from '../../modules/firebase/services/firestore.service';
import { IBankAccount } from '../model/bank-account.model';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class BankAccountService extends FirestoreService<IBankAccount> {

  constructor(
    protected firestore: AngularFirestore,
    private authService: AuthService,
  ) {
    super(firestore);
  }

  public getCollection(): string {
    return 'bank-accounts';
  }

  public getAll(): Observable<IBankAccount[]> {
    const where = (ref: any) => this.getRef(ref);
    return this.firestore.collection<IBankAccount>('bank-accounts', where).valueChanges({ idField: 'id' });
  }

  public getByCompanyId(companyId: string): Observable<IBankAccount[]> {
    const where = (ref: any) => this.getRef(ref).where('companyId', '==', companyId);
    return this.firestore.collection<IBankAccount>('bank-accounts', where).valueChanges({ idField: 'id' });
  }

  private getRef(ref: any): any {
    if (this.authService.user?.role !== 'admin') {
      ref = ref.where('users', 'array-contains', this.authService.user?.id);
    }
    return ref.where('deleted', '==', false);
  }

}
