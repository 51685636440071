import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { IConnection } from './sftp.model';

@Injectable({
  providedIn: 'root'
})
export class SftpService {

  constructor(
    private http: HttpClient,
  ) { }

  public testConnection(host: string, port: string, username: string, password: string): Observable<IConnection> {
    const url = `${environment.api}/wibSftpTestConnection`;
    const data = { host, port, username, password };
    return this.http.post<IConnection>(url, data);
  }
}
