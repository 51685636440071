import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PrivacyPolicyPage } from './pages/privacy-policy/privacy-policy.page';
import { SharedModule } from '../../shared/shared.module';
import { PagesRoutingModule } from './pages-routing.module';
import { EndUserLicenseAgreementPage } from './pages/end-user-license-agreement/end-user-license-agreement.page';
import { HomePage } from './pages/home/home.page';



@NgModule({
  declarations: [
    HomePage,
    PrivacyPolicyPage,
    EndUserLicenseAgreementPage
  ],
  imports: [
    PagesRoutingModule,
    SharedModule,
  ]
})
export class PagesModule { }
