import { NgModule } from '@angular/core';
import { ListPage } from './pages/list/list.page';
import { BankAccountRoutingModule } from './bank-account-routing.module';
import { SharedModule } from '../../shared/shared.module';
import { DetailsPage } from './pages/details/details.page';
import { StatementsComponent } from './components/statements/statements.component';
import { ImportLogsComponent } from './components/import-logs/import-logs.component';
import { ExportLogsComponent } from './components/export-logs/export-logs.component';
import { ImportLogPage } from './pages/import-log/import-log.page';
import { ExportLogPage } from './pages/export-log/export-log.page';
import { ConnectionComponent } from './components/connection/connection.component';
import { ConnectionImportComponent } from './components/connection-import/connection-import.component';
import { ImportSettingsPage } from './pages/import-settings/import-settings.page';
import { ImportSettingsWibSftpComponent } from './components/import-settings-wib-sftp/import-settings-wib-sftp.component';
import { ConnectionExportComponent } from './components/connection-export/connection-export.component';
import { ExportSettingsPage } from './pages/export-settings/export-settings.page';
import { ExportSettingsTwinfieldComponent } from './components/export-settings-twinfield/export-settings-twinfield.component';
import { CreatePage } from './pages/create/create.page';
import { ExportSettingsQuickBooksOnlineComponent } from './components/export-settings-quick-books-online/export-settings-quick-books-online.component';
import { SftpFormComponent } from './components/sftp-form/sftp-form.component';
import { ImportSettingsCmbSftpComponent } from './components/import-settings-cmb-sftp/import-settings-cmb-sftp.component';
import { ImportSettingsMcbSftpComponent } from './components/import-settings-mcb-sftp/import-settings-mcb-sftp.component';
import { ImportSettingsCibcSftpComponent } from './components/import-settings-cibc-sftp/import-settings-cibc-sftp.component';
import { FormComponent } from './components/form/form.component';
import { EditPage } from './pages/edit/edit.page';
import { StatementsPage } from './pages/statements/statements.page';
import { ExportSettingsExactOnlineComponent } from './components/export-settings-exact-online/export-settings-exact-online.component';
import { ImportSettingsPlaidComponent } from './components/import-settings-plaid/import-settings-plaid.component';
import { ConnectButtonComponent } from './components/connect-button/connect-button.component';



@NgModule({
  declarations: [
    ListPage,
    DetailsPage,
    StatementsComponent,
    ImportLogsComponent,
    ExportLogsComponent,
    ImportLogPage,
    ExportLogPage,
    ConnectionComponent,
    ConnectionImportComponent,
    ImportSettingsPage,
    ImportSettingsWibSftpComponent,
    ConnectionExportComponent,
    ExportSettingsPage,
    ExportSettingsTwinfieldComponent,
    CreatePage,
    ExportSettingsQuickBooksOnlineComponent,
    SftpFormComponent,
    ImportSettingsCmbSftpComponent,
    ImportSettingsMcbSftpComponent,
    ImportSettingsCibcSftpComponent,
    FormComponent,
    EditPage,
    StatementsPage,
    ExportSettingsExactOnlineComponent,
    ImportSettingsPlaidComponent,
    ConnectButtonComponent,
  ],
  imports: [
    BankAccountRoutingModule,
    SharedModule,
  ]
})
export class BankAccountModule { }
