import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'filesize' })
export class FilesizePipe implements PipeTransform {
  transform(value: number): string {
    value *= 100;
    if (value < 1024) {
      return `${Math.round(value * 10) / 10} bytes`;
    }
    value /= 1024;
    if (value < 1024) {
      return `${Math.round(value * 10) / 10} Kb`;
    }
    value /= 1024;
    if (value < 1024) {
      return `${Math.round(value * 10) / 10} Mb`;
    }
    value /= 1024;
    return `${Math.round(value * 10) / 10} Gb`;
  }
}