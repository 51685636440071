import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IImportSettingsCmbSftp } from './import-settings-cmb-sftp.model';

@Injectable({
  providedIn: 'root'
})
export class ImportSettingsCmbSftpService {

  constructor(
    private firestore: AngularFirestore,
  ) { }

  public get(bankAccountId: string): Observable<IImportSettingsCmbSftp> {
    const doc = `bank-accounts/${bankAccountId}/settings/import-cmb-sftp`;
    return this.firestore.doc<IImportSettingsCmbSftp>(doc).valueChanges().pipe(
      map((settings) => {
        const defaultSettings: IImportSettingsCmbSftp = {
          host: 'sftp1.mcb-bank.com',
          port: 22,
          username: '',
          password: '',
          folder: '',
        };
        return (settings) ? settings : defaultSettings;
      })
    );
  }

  public save(bankAccountId: string, settings: IImportSettingsCmbSftp): Promise<void> {
    const doc = `bank-accounts/${bankAccountId}/settings/import-cmb-sftp`;
    return this.firestore.doc<IImportSettingsCmbSftp>(doc).set(settings);
  }

}
