import firebase from 'firebase';

export interface IImportLog {
  id?: string;
  datetime: firebase.firestore.Timestamp;
  filename?: string;
  statement?: number;
  lines?: ILogLine[];
  status: ILogStatusEnum;
}

export interface IExportLog {
  id?: string;
  datetime: firebase.firestore.Timestamp;
  lines?: ILogLine[];
  status: ILogStatusEnum;
}

export interface ILogLine {
  message: string;
  timestamp: number;
  status: ILogStatusEnum;
  info: string;
  infoType: string;
}

export enum ILogStatusEnum {
  RUNNING = 'RUNNING',
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
  NONE = 'NONE',
}
