<app-container [authenticationRequired]="false">
  <app-content-block [hasPadding]="false">
    <div class="text-container">
      <div class="text-block">
        <h1>Automate Your<br />Accounting System</h1>
        <p>You don’t need to switch accountants! We specialize in helping businesses including accounting firms in connecting the latest tools and applications to their accounting systems. Through automation we help our clients save time, money and become future proof.</p>
      </div>
      <img src="/assets/images/pages/home/relax.png" alt="Automate Your Accounting System" />
    </div>
  </app-content-block>
</app-container>
