import { Component, Input, OnInit } from '@angular/core';
import { EMPTY, Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { IBankAccount, ISFTPSettings } from '../../../../shared/model/bank-account.model';
import { IImportResponse } from './import-response.model';
import { ImportToolsSftpService } from './import-tools-sftp.service';
import { ISftpFile } from './sftp-file.model';

@Component({
  selector: 'app-import-tools-sftp',
  templateUrl: './import-tools-sftp.component.html',
  styleUrls: ['./import-tools-sftp.component.scss']
})
export class ImportToolsSftpComponent implements OnInit {

  @Input()
  public bankAccount!: IBankAccount;

  @Input()
  public settings!: ISFTPSettings;

  public folders$!: Observable<string[]>;
  public files$!: Observable<ISftpFile[]>;
  public importResponse$!: Observable<IImportResponse>;

  public selectedFolder = '';

  public directoriesError = '';
  public filesError = '';

  constructor(
    private importToolsSftpService: ImportToolsSftpService,
  ) { }

  public ngOnInit(): void {
    this.folders$ = this.importToolsSftpService.getFolders(this.bankAccount.id).pipe(
      catchError((error) => {
        this.directoriesError = error;
        return EMPTY;
      }),
    );
    this.selectedFolder = this.settings.folder;
    this.loadFiles();
  }

  public loadFiles(): void {
    this.filesError = '';
    this.files$ = this.importToolsSftpService.getFiles(this.bankAccount.id, this.selectedFolder).pipe(
      catchError((error) => {
        this.filesError = error;
        return EMPTY;
      }),
    );
  }

  public importFile(file: ISftpFile): void {
    const filename = `/${this.selectedFolder}/${file.name}`;
    this.importResponse$ = this.importToolsSftpService.importFile(this.bankAccount.id, filename).pipe(
      tap(() => this.loadFiles()),
    );
  }

}
