import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../../services/auth.service';
import { IconService } from '../../services/icon.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {

  public form: FormGroup = new FormGroup({
    username: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', Validators.required),
  });

  public loginInvalid: boolean = false;

  constructor(
    private authService: AuthService,
    private iconService: IconService,
  ) {
    this.iconService.loadIcons([
      'account',
      'lock',
    ]);
  }

  public login(): void {
    if (this.form.valid) {
      const username: string = this.form.get('username')!.value;
      const password: string = this.form.get('password')!.value;
      this.authService.login(username, password).then((success: boolean) => {
        this.loginInvalid = !success;
      });
    }
  }

}
