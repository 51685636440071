import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../../../environments/environment';
import { IAccount, IExportSettingsQuickBooksOnline } from './export-settings-quick-books-online.model';

@Injectable({
  providedIn: 'root'
})
export class ExportSettingsQuickBooksOnlineService {

  constructor(
    private firestore: AngularFirestore,
    private http: HttpClient,
  ) { }

  public get(bankAccountId: string): Observable<IExportSettingsQuickBooksOnline> {
    const doc = `bank-accounts/${bankAccountId}/settings/export-quick-books-online`;
    return this.firestore.doc<IExportSettingsQuickBooksOnline>(doc).valueChanges().pipe(
      map((settings) => {
        const defaultSettings: IExportSettingsQuickBooksOnline = {
          accessToken: '',
          refreshToken: '',
          companyId: '',
        };
        return (settings) ? settings : defaultSettings;
      })
    );
  }

  public getAccounts(bankAccountId: string): Observable<IAccount[]> {
    const url = `${environment.api}/quickBooksOnlineAccounts?bankAccountId=${bankAccountId}`;
    return this.http.get<IAccount[]>(url);
  }

  public save(bankAccountId: string, settings: IExportSettingsQuickBooksOnline): Promise<void> {
    const doc = `bank-accounts/${bankAccountId}/settings/export-quick-books-online`;
    return this.firestore.doc<IExportSettingsQuickBooksOnline>(doc).set(settings);
  }

}
