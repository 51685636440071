import { Component, Input } from '@angular/core';
import { ILogLine } from '../../model/log.model';

@Component({
  selector: 'app-log-lines',
  templateUrl: './log-lines.component.html',
  styleUrls: ['./log-lines.component.scss']
})
export class LogLinesComponent {

  @Input()
  public logLines!: ILogLine[];

}
