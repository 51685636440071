import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { FirestoreService } from '../../modules/firebase/services/firestore.service';
import { ICompany } from '../model/company.model';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class CompanyService extends FirestoreService<ICompany> {

  constructor(
    protected firestore: AngularFirestore,
    private authService: AuthService,
  ) {
    super(firestore);
  }

  public getCollection(): string {
    return 'companies';
  }

  public getAll(): Observable<ICompany[]> {
    const collection = this.getCollection();
    return this.firestore.collection<ICompany>(collection, (ref) => this.getRef(ref)).valueChanges({ idField: 'id' });
  }

  private getRef(ref: any): any {
    if (this.authService.user?.role !== 'admin') {
      ref = ref.where('users', 'array-contains', this.authService.user?.id);
    }
    return ref.where('deleted', '==', false);
  }

}
