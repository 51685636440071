<app-card>
  <table class="card">
    <tr>
      <th>Bank account</th>
      <td>
        <a [routerLink]="['/bank-accounts', bankAccount.id]">{{ bankAccount.name }}</a>
      </td>
    </tr>
    <tr>
      <th>Time</th>
      <td>{{ exportLog.datetime | firestoreTimestamp:'YYYY-MM-DD HH:mm:ss' }}</td>
    </tr>
    <tr>
      <th>Status</th>
      <td>{{ exportLog.status }}</td>
    </tr>
  </table>
</app-card>
