import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CreatePage } from './pages/create/create.page';
import { DetailsPage } from './pages/details/details.page';
import { EditPage } from './pages/edit/edit.page';
import { ListPage } from './pages/list/list.page';

const routes: Routes = [
  {
    path: 'companies',
    component: ListPage,
  },
  {
    path: 'companies/create',
    component: CreatePage,
  },
  {
    path: 'companies/:companyId',
    component: DetailsPage,
  },
  {
    path: 'companies/:companyId/edit',
    component: EditPage,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class CompanyRoutingModule { }
