import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IImportLog, ILogLine } from '../model/log.model';

@Injectable({
  providedIn: 'root'
})
export class ImportLogService {

  constructor(
    protected firestore: AngularFirestore,
  ) { }

  public get(bankAccountId: string, id: string): Observable<IImportLog> {
    return this.firestore.collection<IImportLog>(`bank-accounts/${bankAccountId}/importLogs`).doc(id).get().pipe(
      map(snapshot => snapshot.data() as IImportLog),
    );
  }

  public getAll(bankAccountId: string): Observable<IImportLog[]> {
    const collection = `bank-accounts/${bankAccountId}/importLogs`;
    return this.firestore.collection<IImportLog>(collection, ref => ref.orderBy('datetime', 'desc').limit(5)).valueChanges({ idField: 'id' });
  }

  public getLogLines(bankAccountId: string, importLogId: string): Observable<ILogLine[]> {
    const collection = `bank-accounts/${bankAccountId}/importLogs/${importLogId}/lines`;
    return this.firestore.collection<ILogLine>(collection, ref => ref.orderBy('timestamp')).valueChanges();
  }

  public getByFilename(bankAccountId: string, filename: string): Observable<IImportLog[]> {
    const collection = `bank-accounts/${bankAccountId}/importLogs`;
    return this.firestore.collection<IImportLog>(collection, ref => ref.where('filename', '==', filename)).valueChanges();
  }

}
