import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ICompany } from '../../../../shared/model/company.model';
import { ConfirmDialogComponent, IConfirmData } from '../../../../shared/components/confirm-dialog/confirm-dialog.component';
import { IBankAccount } from '../../../../shared/model/bank-account.model';
import { BankAccountService } from '../../../../shared/services/bank-account.service';
import { IconService } from '../../../../shared/services/icon.service';
import { switchMap } from 'rxjs/operators';
import { CompanyService } from '../../../../shared/services/company.service';

@Component({
  selector: 'app-details',
  templateUrl: './details.page.html',
  styleUrls: ['./details.page.scss']
})
export class DetailsPage implements OnInit {

  public bankAccount$!: Observable<IBankAccount>;
  public company$!: Observable<ICompany>;

  constructor(
    private bankAccountService: BankAccountService,
    private companyService: CompanyService,
    private route: ActivatedRoute,
    private iconService: IconService,
    private router: Router,
    private dialog: MatDialog,
  ) {
    this.iconService.loadIcons([
      'checked',
      'edit',
      'delete',
      'settings',
    ]);
  }

  public ngOnInit(): void {
    const id = this.route.snapshot.paramMap.get('bankAccountId');
    if (id) {
      this.bankAccount$ = this.bankAccountService.get(id);
      this.company$ = this.bankAccount$.pipe(
        switchMap((bankAccount) => this.companyService.get(bankAccount.companyId)),
      )
    }
  }

  public toggleActive(bankAccount: IBankAccount): void {
    bankAccount.active = !bankAccount.active;
    this.bankAccountService.update(bankAccount);
  }

  public showDeleteDialog(bankAccount: IBankAccount): void {
    const data: IConfirmData = {
      title: `Delete bank account "${bankAccount.name}"?`,
      message: 'This will delete the bank account with all it\'s data and cannot be undone.'
    };
    const dialog = this.dialog.open(ConfirmDialogComponent, { data });
    dialog.afterClosed().subscribe((confirm) => {
      if (confirm) {
        this.delete(bankAccount);
      }
    });
  }

  public delete(bankAccount: IBankAccount): void {
    bankAccount.deleted = true;
    this.bankAccountService.update(bankAccount).then(() => {
      this.router.navigate(['/companies', bankAccount.companyId]);
    });
  }

}
