import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-import-icon',
  templateUrl: './import-icon.component.html',
  styleUrls: ['./import-icon.component.scss']
})
export class ImportIconComponent implements OnInit {

  @Input()
  public importType!: string;

  public icon = '';

  public ngOnInit(): void {
    if (this.importType) {
      this.icon = `/assets/images/import/icons/${this.importType}.png`;
    } else {
      this.icon = '/assets/images/import/icons/unknown.png';
    }
  }

}
