import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IImportSettingsCibcSftp } from './import-settings-cibc-sftp.model';

@Injectable({
  providedIn: 'root'
})
export class ImportSettingsCibcSftpService {

  constructor(
    private firestore: AngularFirestore,
  ) { }

  public get(bankAccountId: string): Observable<IImportSettingsCibcSftp> {
    const doc = `bank-accounts/${bankAccountId}/settings/import-cibc-sftp`;
    return this.firestore.doc<IImportSettingsCibcSftp>(doc).valueChanges().pipe(
      map((settings) => {
        const defaultSettings: IImportSettingsCibcSftp = {
          host: '34.135.94.76',
          port: 22,
          username: '',
          password: '',
          folder: '',
        };
        return (settings) ? settings : defaultSettings;
      })
    );
  }

  public save(bankAccountId: string, settings: IImportSettingsCibcSftp): Promise<void> {
    const doc = `bank-accounts/${bankAccountId}/settings/import-cibc-sftp`;
    return this.firestore.doc<IImportSettingsCibcSftp>(doc).set(settings);
  }

}
