<form>

  <app-sftp-form [formGroup]="form"></app-sftp-form>

  <div class="buttons">
    <button mat-flat-button color="primary" (click)="save()">Save</button>
    <button mat-stroked-button [routerLink]="['/bank-accounts', bankAccount.id]">Cancel</button>
  </div>

</form>
