import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { combineLatest, Observable } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { IBankAccount } from '../../../../shared/model/bank-account.model';
import { environment } from '../../../../../environments/environment';
import { IDivision, IExportSettingsExactOnline, IGLAccount, IJournal } from './export-settings-exact-online.model';
import { BankAccountService } from '../../../../shared/services/bank-account.service';

@Injectable({
  providedIn: 'root'
})
export class ExportSettingsExactOnlineService {

  constructor(
    private firestore: AngularFirestore,
    private http: HttpClient,
    private bankAccountService: BankAccountService,
  ) { }

  public get(bankAccountId: string): Observable<IExportSettingsExactOnline> {
    const doc = `bank-accounts/${bankAccountId}/settings/export-exact-online`;
    return this.firestore.doc<IExportSettingsExactOnline>(doc).valueChanges().pipe(
      map((settings) => {
        const defaultSettings: IExportSettingsExactOnline = {};
        return (settings) ? settings : defaultSettings;
      })
    );
  }

  public getConnectedBankAccount(companyId: string): Observable<IBankAccount> {
    return this.bankAccountService.getByCompanyId(companyId).pipe(
      switchMap(bankAccounts => {
        const observables = bankAccounts.map(bankAccount => {
          return this.get(bankAccount.id).pipe(
            map(settings => ({ bankAccount, settings })),
          );
        });
        return combineLatest(observables);
      }),
      map((values) => {
        return values.filter(({settings}) => settings && settings?.accessToken);
      }),
      map(value => value[0].bankAccount),
    );
  }

  public getGLAccounts(bankAccountId: string): Observable<IGLAccount[]> {
    const url = `${environment.api}/exactOnlineGlAccounts?bankAccountId=${bankAccountId}`;
    return this.http.get<IGLAccount[]>(url);
  }

  public getJournals(bankAccountId: string): Observable<IJournal[]> {
    const url = `${environment.api}/exactOnlineJournals?bankAccountId=${bankAccountId}`;
    return this.http.get<IJournal[]>(url);
  }

  public getDivisions(bankAccountId: string): Observable<IDivision[]> {
    const url = `${environment.api}/exactOnlineDivisions?bankAccountId=${bankAccountId}`;
    return this.http.get<IDivision[]>(url);
  }

  public save(bankAccountId: string, settings: IExportSettingsExactOnline): Promise<void> {
    const doc = `bank-accounts/${bankAccountId}/settings/export-exact-online`;
    return this.firestore.doc<IExportSettingsExactOnline>(doc).set(settings);
  }

  public update(bankAccountId: string, settings: IExportSettingsExactOnline): Promise<void> {
    const doc = `bank-accounts/${bankAccountId}/settings/export-exact-online`;
    return this.firestore.doc<IExportSettingsExactOnline>(doc).update(settings);
  }

}
