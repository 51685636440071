import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AngularFireModule } from '@angular/fire';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { environment } from '../environments/environment';
import { BankAccountModule } from './modules/bank-account/bank-account.module';
import { USE_EMULATOR as AUTH_EMULATOR } from '@angular/fire/auth';
import { USE_EMULATOR as FIRESTORE_EMULATOR } from '@angular/fire/firestore';
import { CompanyModule } from './modules/company/company.module';
import { PagesModule } from './modules/pages/pages.module';
import { AuthModule } from './modules/auth/auth.module';
import { DeveloperModule } from './modules/developer/developer.module';

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebase),
    AuthModule,
    CompanyModule,
    BankAccountModule,
    PagesModule,
    DeveloperModule,
  ],
  providers: [
    {
      provide: AUTH_EMULATOR,
      useValue: environment.emulator ? ['localhost', 9099] : undefined,
    },
    {
      provide: FIRESTORE_EMULATOR,
      useValue: environment.emulator ? ['localhost', 8081] : undefined,
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
