<div *ngIf="logLine.infoType === 'text'">
  {{ logLine.info }}
</div>

<div *ngIf="logLine.infoType === 'csv'" class="container">
  <div class="code">
    <pre>{{ logLine.info }}</pre>
  </div>
</div>

<div *ngIf="logLine.infoType === 'json'" class="container">
  <div class="code">
    <pre>{{ logLine.info }}</pre>
  </div>
</div>

<div *ngIf="logLine.infoType === 'xml'" class="container">
  <div class="code">
    <pre>{{ logLine.info }}</pre>
  </div>
</div>