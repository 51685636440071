import { Injectable } from '@angular/core';
import firebase from 'firebase/app';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class FirebaseUtilService {

  public static getTimestamp(date: Date | string): firebase.firestore.Timestamp {
    const seconds = moment(date).unix();
    return new firebase.firestore.Timestamp(seconds, 0);
  }
}
