<app-container>

  <app-content-block cssStyle="form">

    <form *ngIf="company$ | async as company" [formGroup]="form">

      <h1>Edit company</h1>

      <app-form [form]="form"></app-form>

      <div class="buttons">
        <button mat-flat-button color="primary" (click)="submit(company)">Edit</button>
        <button mat-stroked-button [routerLink]="['/companies', company.id]">Cancel</button>
      </div>

    </form>

  </app-content-block>

</app-container>