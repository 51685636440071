export const environment = {
  production: true,
  emulator: false,
  api: 'https://us-central1-tactly-demo.cloudfunctions.net/',
  firebase: {
    apiKey: 'AIzaSyCnshEqmjNVcdVXCrNJ2Dt_6o20xw_toOg',
    authDomain: 'tactly-demo.firebaseapp.com',
    projectId: 'tactly-demo',
    storageBucket: 'tactly-demo.appspot.com',
    messagingSenderId: '771094988711',
    appId: '1:771094988711:web:17e2952745c37ede275882'
  },
  title: 'Tactly - Demo',
};
