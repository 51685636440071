import { Component } from '@angular/core';
import { BankAccountService } from '../../../../shared/services/bank-account.service';

@Component({
  selector: 'app-list',
  templateUrl: './list.page.html',
  styleUrls: ['./list.page.scss']
})
export class ListPage {

  public bankAccounts$ = this.bankAccountService.getAll();

  constructor(
    private bankAccountService: BankAccountService,
  ) { }

}
