import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { IBankAccount } from '../../../../shared/model/bank-account.model';
import { IImportLog, ILogLine } from '../../../../shared/model/log.model';
import { BankAccountService } from '../../../../shared/services/bank-account.service';
import { ImportLogService } from '../../../../shared/services/import-log.service';

@Component({
  selector: 'app-import-log-page',
  templateUrl: './import-log.page.html',
  styleUrls: ['./import-log.page.scss']
})
export class ImportLogPage implements OnInit {

  public bankAccount$!: Observable<IBankAccount>;
  public importLog$!: Observable<IImportLog>;
  public logLines$!: Observable<ILogLine[]>;

  constructor(
    private importLogService: ImportLogService,
    private bankAccountService: BankAccountService,
    private route: ActivatedRoute,
  ) { }

  public ngOnInit(): void {
    const bankAccountId = this.route.snapshot.paramMap.get('bankAccountId') as string;
    const importLogId = this.route.snapshot.paramMap.get('importLogId') as string;
    this.bankAccount$ = this.bankAccountService.get(bankAccountId);
    this.importLog$ = this.importLogService.get(bankAccountId, importLogId);
    this.logLines$ = this.importLogService.getLogLines(bankAccountId, importLogId);
  }

}
