<table *ngIf="statements$ | async as statements" class="data no-border">
  <tr>
    <th>Date</th>
    <th>Number</th>
    <th>Transactions</th>
    <th>Exported</th>
  </tr>
  <tr
    *ngFor="let statement of statements"
  >
    <td>{{ statement.date | firestoreTimestamp:'M/D' }}</td>
    <td>{{ statement.number }}</td>
    <td class="text-center">{{ statement.numberOfTransactions }}</td>
    <td class="text-center icon">
      <mat-icon [svgIcon]="(statement.isExported) ? 'checked' : 'unchecked'"></mat-icon>
    </td>
  </tr>
  <tr *ngIf="statements.length === 0">
    <td colspan="4" class="text-center">No statements found</td>
  </tr>
  <tr *ngIf="statements.length > 0">
    <td colspan="4" class="text-center">
      <a class="link" [routerLink]="['/bank-accounts', bankAccountId, 'statements']">More statements</a>
    </td>
  </tr>
</table>
