import { Injectable } from '@angular/core';
import { AngularFirestore, DocumentReference } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IDocument } from '../model/document.model';

@Injectable({
  providedIn: 'root'
})
export abstract class FirestoreService<T extends IDocument> {

  constructor(
    protected firestore: AngularFirestore,
  ) { }

  protected abstract getCollection(): string;

  public get(id: string): Observable<T> {
    const collection = this.getCollection();
    return this.firestore.collection<T>(collection).doc(id).get().pipe(
      map(snapshot => {
        const document = snapshot.data() as T;
        document.id = id;
        return document;
      }),
    );
  }

  public getAll(): Observable<T[]> {
    const collection = this.getCollection();
    return this.firestore.collection<T>(collection).valueChanges({ idField: 'id' });
  }

  public create(document: T): Promise<T> {
    const collection = this.getCollection();
    return this.firestore.collection<T>(collection).add(document).then((ref: DocumentReference) => {
      document.id = ref.id;
      return document;
    });
  }

  public update(document: T): Promise<void> {
    const collection = this.getCollection();
    const documentPath = `${collection}/${document.id}`;
    const clonedDocument = { ...document }
    delete clonedDocument.id;
    return this.firestore.doc<T>(documentPath).update(clonedDocument);
  }
}
