<app-container>
  <app-content-block>
    <ng-container *ngIf="bankAccounts$ | async as bankAccounts; else loading">
      <app-bank-accounts [bankAccounts]="bankAccounts"></app-bank-accounts>
    </ng-container>
    <ng-template #loading>
      <mat-spinner></mat-spinner>
    </ng-template>
  </app-content-block>
</app-container>
