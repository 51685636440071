import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../../../shared/services/auth.service';

@Component({
  selector: 'app-logout',
  template: '',
})
export class LogoutPage {

  constructor(
    private authService: AuthService,
    private router: Router,
  ) {
    this.authService.logout();
    this.navigate();
  }

  private navigate(): void {
    this.router.navigate(['']);
  }

}
