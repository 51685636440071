import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../../../environments/environment';
import { IExportSettingsTwinfield } from './export-settings-twinfield.model';

@Injectable({
  providedIn: 'root'
})
export class ExportSettingsTwinfieldService {

  constructor(
    private firestore: AngularFirestore,
    private http: HttpClient,
  ) { }

  public get(bankAccountId: string): Observable<IExportSettingsTwinfield> {
    const doc = `bank-accounts/${bankAccountId}/settings/export-twinfield`;
    return this.firestore.doc<IExportSettingsTwinfield>(doc).valueChanges().pipe(
      map((settings) => {
        const defaultSettings: IExportSettingsTwinfield = {
          accessToken: '',
          clusterUrl: '',
          companyCode: '',
          refreshToken: '',
        };
        return (settings) ? settings : defaultSettings;
      })
    );
  }

  public getCompanies(bankAccountId: string): Observable<string[]> {
    const url = `${environment.api}/twinfieldCompanies?bankAccountId=${bankAccountId}`;
    return this.http.get<string[]>(url);
  }

  public save(bankAccountId: string, settings: IExportSettingsTwinfield): Promise<void> {
    const doc = `bank-accounts/${bankAccountId}/settings/export-twinfield`;
    return this.firestore.doc<IExportSettingsTwinfield>(doc).set(settings);
  }

}
