import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IImportSettingsWibSftp } from '../../model/import-settings/import-settings-wib-sftp.model';

@Injectable({
  providedIn: 'root'
})
export class ImportSettingsWibSftpService {

  constructor(
    private firestore: AngularFirestore,
  ) { }

  public get(bankAccountId: string): Observable<IImportSettingsWibSftp> {
    const doc = `bank-accounts/${bankAccountId}/settings/import-wib-sftp`;
    return this.firestore.doc<IImportSettingsWibSftp>(doc).valueChanges().pipe(
      map((settings) => {
        const defaultSettings: IImportSettingsWibSftp = {
          host: 'sftp1.mcb-bank.com',
          port: 22,
          username: '',
          password: '',
          folder: '',
        };
        return (settings) ? settings : defaultSettings;
      })
    );
  }

  public save(bankAccountId: string, settings: IImportSettingsWibSftp): Promise<void> {
    const doc = `bank-accounts/${bankAccountId}/settings/import-wib-sftp`;
    return this.firestore.doc<IImportSettingsWibSftp>(doc).set(settings);
  }

}
