import { Component, Input, OnInit } from '@angular/core';
import { IBankAccount } from '../../../../shared/model/bank-account.model';
import { IconService } from '../../../../shared/services/icon.service';

@Component({
  selector: 'app-connection-import',
  templateUrl: './connection-import.component.html',
  styleUrls: ['./connection-import.component.scss']
})
export class ConnectionImportComponent implements OnInit {

  @Input()
  public bankAccount!: IBankAccount;

  public imageSrc = '';

  public ngOnInit(): void {
    this.imageSrc = `/assets/images/import/${this.bankAccount.importType}.png`
  }

}
