import { Component, Input, OnInit } from '@angular/core';
import { IBankAccount } from '../../../../shared/model/bank-account.model';

@Component({
  selector: 'app-connection-export',
  templateUrl: './connection-export.component.html',
  styleUrls: ['./connection-export.component.scss']
})
export class ConnectionExportComponent implements OnInit {

  @Input()
  public bankAccount!: IBankAccount;

  public imageSrc = '';

  public ngOnInit(): void {
    this.imageSrc = `/assets/images/export/${this.bankAccount.exportType}.png`
  }

}
