<app-container>

  <ng-container *ngIf="bankAccount">

    <div *ngIf="!bankAccount.exportType">
      <ng-container *ngIf="exportTypes$ | async as exportTypes; else loading">
        <div
          *ngFor="let exportType of exportTypes"
          class="export-type clickable-item"
          (click)="setExportType(exportType.code)"
        >
          <img src="/assets/images/export/{{ exportType.code }}.png" />
          <h3>{{ exportType.name }}</h3>
          <p>{{ exportType.description }}</p>
        </div>
      </ng-container>
      <ng-template #loading>
        <app-spinner type="content-block"></app-spinner>
      </ng-template>
    </div>

    <app-content-block *ngIf="bankAccount.exportType" cssStyle="form">

      <div *ngIf="bankAccount.exportType" class="selected-export-type">
        <img src="/assets/images/export/{{ bankAccount.exportType }}.png" />
        <div class="change-export-type clickable-item" (click)="setExportType('')">
          <mat-icon svgIcon="change"></mat-icon><br />
          <span>Select different export type</span>
        </div>
      </div>

      <app-export-settings-exact-online
        *ngIf="bankAccount.exportType === 'exact-online'"
        [bankAccount]="bankAccount"
      ></app-export-settings-exact-online>

      <app-export-settings-twinfield
        *ngIf="bankAccount.exportType === 'twinfield'"
        [bankAccount]="bankAccount"
      ></app-export-settings-twinfield>

      <app-export-settings-quick-books-online
        *ngIf="bankAccount.exportType === 'quick-books-online'"
        [bankAccount]="bankAccount"
      ></app-export-settings-quick-books-online>

    </app-content-block>

  </ng-container>

</app-container>
