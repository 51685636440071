import { Component, Input, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-auth-container',
  templateUrl: './auth-container.component.html',
  styleUrls: ['./auth-container.component.scss']
})
export class AuthContainerComponent implements OnInit {

  @Input()
  public authenticationRequired = true;

  public authState$ = this.authService.authState;

  constructor(
    private authService: AuthService,
  ) { }

  ngOnInit(): void {
  }

}
