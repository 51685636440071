import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { IconService } from '../../../../shared/services/icon.service';
import { IConnection } from './sftp.model';
import { SftpService } from './sftp.service';

@Component({
  selector: 'app-sftp-form',
  templateUrl: './sftp-form.component.html',
  styleUrls: ['./sftp-form.component.scss']
})
export class SftpFormComponent implements OnInit {

  @Input()
  public formGroup!: FormGroup;

  public isLoading: boolean = false;
  public connection!: IConnection;
  public canCheckConnection: boolean = false;

  constructor(
    private iconService: IconService,
    private sftpService: SftpService,
  ) {
    this.iconService.loadIcons([
      'dns',
      'cable',
      'account',
      'lock',
      'folder',
    ]);
  }

  public ngOnInit(): void {
    this.formGroup.valueChanges.subscribe(() => {
      const validHost = this.formGroup.get('host')?.valid;
      const validPort = this.formGroup.get('port')?.valid;
      const validUsername = this.formGroup.get('username')?.valid;
      const validPassword = this.formGroup.get('password')?.valid;
      this.canCheckConnection = (validHost && validPort && validUsername && validPassword)!;
    });
  }

  public testConnection(): void {
    this.isLoading = true;
    this.sftpService.testConnection(
      this.formGroup.get('host')?.value,
      this.formGroup.get('port')?.value,
      this.formGroup.get('username')?.value,
      this.formGroup.get('password')?.value,
    ).subscribe((connection) => {
      this.isLoading = false;
      this.connection = connection;
      if (connection.status === 'success') {
        this.formGroup.get('folder')?.enable();
        const folder = (connection.folders.includes('STATEMENTS')) ? 'STATEMENTS' : '';
        this.formGroup.get('folder')?.setValue(folder);
      } else {
        this.formGroup.get('folder')?.disable();
        this.formGroup.get('folder')?.setValue('');
      }
    });
  }

}
