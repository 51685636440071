import { Component } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../../../../shared/services/auth.service';

@Component({
  selector: 'app-login-page',
  templateUrl: './login.page.html',
  styleUrls: ['./login.page.scss']
})
export class LoginPage {

  constructor(
    private authService: AuthService,
    private router: Router,
  ) {
    this.authService.authState.subscribe((user) => {
      if (user) {
        this.navigate();
      }
    })
  }

  private navigate(): void {
    this.router.navigate(['']);
  }

}
