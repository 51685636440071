import { Component, Input } from '@angular/core';
import { IBankAccount } from '../../model/bank-account.model';
import { IImportLog } from '../../model/log.model';

@Component({
  selector: 'app-import-log',
  templateUrl: './import-log.component.html',
  styleUrls: ['./import-log.component.scss']
})
export class ImportLogComponent {

  @Input()
  public bankAccount!: IBankAccount;

  @Input()
  public importLog!: IImportLog;

}
