import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-connect-button',
  templateUrl: './connect-button.component.html',
  styleUrls: ['./connect-button.component.scss']
})
export class ConnectButtonComponent {

  @Input()
  public connectText = 'Connect';

  @Input()
  public connectedText = 'Connected';

  @Input()
  public disconnectText = 'Disconnect';

  @Input()
  public connected!: boolean | null;

  @Output()
  public connect: EventEmitter<void> = new EventEmitter();

  @Output()
  public disconnect: EventEmitter<void> = new EventEmitter();

  public handleConnect(): void {
    if (!this.connected) {
      this.connect.emit();
    }
  }

}
