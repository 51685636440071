import { Component, Input, OnInit } from '@angular/core';
import { IImportResponse } from '../import-response.model';

@Component({
  selector: 'app-import-response',
  templateUrl: './import-response.component.html',
  styleUrls: ['./import-response.component.scss']
})
export class ImportResponseComponent implements OnInit {

  @Input()
  public importResponse!: IImportResponse;

  constructor() { }

  ngOnInit(): void {
  }

}
