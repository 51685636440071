import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { environment } from '../environments/environment';

@Component({
  selector: 'app-root',
  template: '<router-outlet></router-outlet>',
})
export class AppComponent implements OnInit {

  public constructor(
    private titleService: Title,
  ) { }

  public ngOnInit(): void {
    this.titleService.setTitle(environment.title);
  }
}
