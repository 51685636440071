import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Observable } from 'rxjs';
import { IUser } from '../model/user.model';
import firebase from 'firebase/app';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  public set user(user: IUser | null) {
    localStorage.setItem('tactly-user', JSON.stringify(user));
  }

  public get user(): IUser | null {
    return JSON.parse(localStorage.getItem('tactly-user')!);
  }

  constructor(
    private auth: AngularFireAuth,
  ) { }

  public authState: Observable<firebase.User | null> = this.auth.authState;

  public isValid(): boolean {
    return (this.user !== null) ? true : false;
  }

  public login(email: string, password: string): Promise<any> {
    return this.auth.signInWithEmailAndPassword(email, password)
      .then(response => {
        this.setUser(response.user);
        return true;
      })
      .catch(error => false);
  }

  private setUser(user: any): void {

    // Ugly workaround
    const admins = [
      'r.brouwer@tactly.io',
      'k.daniel@tactly.io',
    ];

    const role = admins.includes(user.email) ? 'admin' : 'user';

    this.user = {
      id: user.uid,
      firstname: '',
      lastname: '',
      displayName: user.displayName,
      role,
    };
  }

  public isAdmin(): boolean {
    return this.user?.role === 'admin';
  }

  public logout(): void {
    this.user = null;
    this.auth.signOut();
  }
}
