import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { FirestoreService } from '../../modules/firebase/services/firestore.service';
import { IUser } from '../model/user.model';

@Injectable({
  providedIn: 'root'
})
export class UserService extends FirestoreService<IUser> {

  public getCollection(): string {
    return 'users';
  }

  constructor(
    private auth: AngularFireAuth,
    protected firestore: AngularFirestore,
  ) {
    super(firestore);
  }

  public getCurrentUser(): Observable<IUser> {
    const defaultUser: IUser = {
      firstname: 'Default',
      lastname: 'User',
      displayName: 'Default User',
      role: 'user',
    };
    return this.auth.authState.pipe(
      switchMap((authState) => (authState) ? this.get(authState.uid) : of(defaultUser)),
      map((user) => {
        if (!user.firstname || !user.lastname) {
          const displayName = user.displayName?.split(' ');
          user.firstname = (displayName && displayName[0]) ? displayName[0] : '';
          user.lastname = (displayName && displayName[1]) ? displayName.slice(1).join(' ') : '';
        }
        return user;
      }),
    )
  }

}
