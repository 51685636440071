import { Component, Input } from '@angular/core';
import { IBankAccount } from '../../model/bank-account.model';
import { IExportLog } from '../../model/log.model';

@Component({
  selector: 'app-export-log',
  templateUrl: './export-log.component.html',
  styleUrls: ['./export-log.component.scss']
})
export class ExportLogComponent {

  @Input()
  public bankAccount!: IBankAccount;

  @Input()
  public exportLog!: IExportLog;

}
