<app-container [authenticationRequired]="false">
  <app-content-block cssStyle="white">

    <h1>Privacy policy</h1>

    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi ultrices blandit enim, non pellentesque augue suscipit quis. Donec fermentum justo vel leo aliquam pulvinar. Interdum et malesuada fames ac ante ipsum primis in faucibus.</p>

    <p>Nulla elementum ligula in tellus aliquam, vitae bibendum diam dictum. Morbi gravida pharetra arcu, nec rutrum neque egestas in. Pellentesque euismod mauris nunc. Nulla vitae mauris neque. Aenean ac nulla libero.</p>

  </app-content-block>
</app-container>