<div class="importType" *ngIf="bankAccount.importType; else selectImportType">
  <div class="select clickable-item" [routerLink]="['/bank-accounts', bankAccount.id, 'import-settings']">
    <img [src]="imageSrc" />
  </div>
  <app-import-logs [bankAccountId]="bankAccount.id"></app-import-logs>
</div>

<ng-template #selectImportType>
  <div class="select clickable-item" [routerLink]="['/bank-accounts', bankAccount.id, 'import-settings']">
    <h3>From where can we import your transactions?</h3>
  </div>
</ng-template>
