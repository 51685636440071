import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { IBankAccount } from '../../../../shared/model/bank-account.model';
import { IExportLog, ILogLine } from '../../../../shared/model/log.model';
import { BankAccountService } from '../../../../shared/services/bank-account.service';
import { ExportLogService } from '../../../../shared/services/export-log.service';

@Component({
  selector: 'app-export-log-page',
  templateUrl: './export-log.page.html',
  styleUrls: ['./export-log.page.scss']
})
export class ExportLogPage implements OnInit {

  public bankAccount$!: Observable<IBankAccount>;
  public exportLog$!: Observable<IExportLog>;
  public logLines$!: Observable<ILogLine[]>;

  constructor(
    private exportLogService: ExportLogService,
    private bankAccountService: BankAccountService,
    private route: ActivatedRoute,
  ) { }

  public ngOnInit(): void {
    const bankAccountId = this.route.snapshot.paramMap.get('bankAccountId') as string;
    const exportLogId = this.route.snapshot.paramMap.get('exportLogId') as string;
    this.bankAccount$ = this.bankAccountService.get(bankAccountId);
    this.exportLog$ = this.exportLogService.get(bankAccountId, exportLogId);
    this.logLines$ = this.exportLogService.getLogLines(bankAccountId, exportLogId);
  }

}
