<div class="text-center" class="connect">
  <div class="connect-button clickable-item" (click)="connect()">
    <mat-icon class="icon" matSuffix svgIcon="lock"></mat-icon>
    Connect to QuickBooks Online
    <div
      class="status"
      [matTooltip]="(connected) ? 'Connected' : 'Not connected'"
      [ngClass]="{
      active: connected,
      inactive: !connected
    }"></div>
  </div>
</div>

<div *ngIf="!connected" class="cancel text-center">
  <button mat-stroked-button (click)="cancel()">Cancel</button>
  <div class="comment">Don't use QuickBooks online as my export type</div>
</div>

<form [formGroup]="form" *ngIf="connected">
  <ng-container *ngIf="accounts$ | async as accounts; else loadingAccounts">
    <div>
      <mat-form-field appearance="outline">
        <mat-label>Bank account</mat-label>
        <select matNativeControl formControlName="bankAccount">
          <option value=""></option>
          <option *ngFor="let account of accounts" [value]="account.value">{{ account.name }}</option>
        </select>
        <mat-icon matSuffix svgIcon="company"></mat-icon>
      </mat-form-field>
    </div>
    <div>
      <mat-form-field appearance="outline">
        <mat-label>Suspense account</mat-label>
        <select matNativeControl formControlName="suspenseAccount">
          <option value=""></option>
          <option *ngFor="let account of accounts" [value]="account.value">{{ account.name }}</option>
        </select>
        <mat-icon matSuffix svgIcon="company"></mat-icon>
      </mat-form-field>
    </div>
    <div class="buttons">
      <button mat-flat-button color="primary" (click)="save()">Save</button>
      <button mat-stroked-button [routerLink]="['/bank-accounts', bankAccount.id]">Cancel</button>
    </div>
  </ng-container>
</form>

<ng-template #loadingAccounts>
  <app-spinner>Loading account data</app-spinner>
</ng-template>
