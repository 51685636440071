import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-export-icon',
  templateUrl: './export-icon.component.html',
  styleUrls: ['./export-icon.component.scss']
})
export class ExportIconComponent implements OnInit {

  @Input()
  public exportType!: string;

  public icon = '';

  public ngOnInit(): void {
    if (this.exportType) {
      this.icon = `/assets/images/export/icons/${this.exportType}.png`;
    } else {
      this.icon = '/assets/images/export/icons/unknown.png';
    }
  }

}
